import React from "react";
import HsixRectangle from "../../Assets/HsixRectangle.png";
import HomeSixNav from "../../Components/home-six/HomeSixNav";
import HomeSixCard from "../../Components/home-six/HomeSixCard";
import HsixRectangle1 from "../../Assets/HsixRectangle1.png";
import HsixIcon1 from "../../Assets/HsixIcon1.png";
import HsixArrow1 from "../../Assets/HsixArrow1.png";
import HsixRectangle2 from "../../Assets/HsixRectangle2.png";
import HsixRectangle3 from "../../Assets/HsixRectangle3.png";
import HsixRectangle4 from "../../Assets/HsixRectangle4.png";
import HsixRectangle5 from "../../Assets/HsixRectangle5.png";
import HsixRectangle6 from "../../Assets/HsixRectangle6.png";
import { socials } from "../../Components/Footer";
import AppLogo from "../../Components/app-logo";
import { Link } from "react-scroll";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const HomeSix = () => {
	const backgroundColors = [
			"#FEEFEA",
			"#FFFBEF",
			"#EFF6FE",
			"#FEEFEA",
			"#FAEFFE",
			"#EFFEF0",
		],
		navigate = useNavigate();

	const cardData = [
		{
			image: HsixRectangle1,
			imagee: HsixIcon1,
			title: "Data Service",
			text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			buttonText: "LEARN MORE",
			arrow: HsixArrow1,
		},
		{
			image: HsixRectangle2,
			imagee: HsixIcon1,
			title: "Airtime Pin Services",
			text: `${process.env.REACT_APP_AGENT_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our low charges and quick recharge services make it easy for customers to top up their airtime.`,
			buttonText: "LEARN MORE",
			arrow: HsixArrow1,
		},
		{
			image: HsixRectangle3,
			imagee: HsixIcon1,
			title: "Cable Subscription",
			text: `At ${process.env.REACT_APP_AGENT_NAME}, We offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service`,
			buttonText: "LEARN MORE",
			arrow: HsixArrow1,
		},
		{
			image: HsixRectangle4,
			imagee: HsixIcon1,
			title: "Electricty",
			text: "If you want to stay on top of your finances, then you can make use of our pocket-friendly electricity payment feature. It saves time and money with our streamlined payment tools.",
			buttonText: "LEARN MORE",
			arrow: HsixArrow1,
		},
		{
			image: HsixRectangle5,
			imagee: HsixIcon1,
			title: "Education",
			text: `${process.env.REACT_APP_AGENT_NAME}  is your go-to source for various examination scratch cards pins, including Waec, Neco, and Nabteb examinations`,
			buttonText: "LEARN MORE",
			arrow: HsixArrow1,
		},
		{
			image: HsixRectangle6,
			imagee: HsixIcon1,
			title: "Airtime Services",
			text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			buttonText: "LEARN MORE",
			arrow: HsixArrow1,
		},
	];

	return (
		<div className="w-full overflow-hidden mx-auto">
			<div
				className=" flex bg-cover bg-center bg-no-repeat"
				style={{
					backgroundImage: `url(${HsixRectangle})`,
					backgroundSize: "150% 105%",
				}}>
				<HomeSixNav />
				<div className=" lg:flex md:flex grid md:px-16 lg:px-28 px-8 lg:mt-16 md:mt35 mt-20  justify-center w-full h-full">
					<div className="lg:w-1/2 md:w-1/2 w-full">
						<img
							className=" w-3/4 h-full"
							src={require("../../Assets/HsixHero.png")}
							alt=""
						/>
					</div>

					<div className=" grid lg:w-1/2 md:w-1/2 w-full lg:mt-5">
						<p className=" relative lg:top-5 lg:bottom-0 md:bottom-[-20px] bottom-1 lg:text-[15px] md:text-[15px] text-[8px] font-bold text-homeSixCol">
							WE ARE THE BEST SUBSCRIPTION PLATFORM
						</p>
						<div className="flex">
							<img
								className=" object-cover lg:h-96 md:h-96 lg:flex md:flex hidden relative lg:right-10 lg:top-7 md:right-10 md:bottom-[-38px]"
								src={require("../../Assets/HsixLine.png")}
								alt=""
							/>
							<p className=" relative lg:bottom-0 md:bottom-[-30px] bottom-3 text-white lg:text-[70px] md:text-[50px] text-[30px] ">
								Never run out of data. Stay Connected!
							</p>
						</div>
						<p className="text-white w-96 h-auto relative lg:bottom-8 md:bottom-8 bottom-5">
							In a few clicks, buy data to keep surfing the internet. You can
							buy whatever size of data plan for whichever network you desire.
							Get Started!
						</p>
						<div>
							<RouterLink
								to="/login"
								className="flex lg:justify-start md:justify-start justify-center items-center space-x-5">
								<button className=" mb-5 transition-transform hover:scale-110 duration-300 ease-in text-white bg-transparent border-gray-400 border-4 lg:w-[170px] lg:h-[42px] md:w-[130px] md:h-[38px] w-[120px] h-[30px] rounded-3xl">
									Get Started
								</button>
								<img
									className="lg:w-8 lg:h-8 md:w-6 md:h-6 sm:w-4 h-4"
									src={require("../../Assets/HsixArrow.png")}
									alt=""
								/>
							</RouterLink>
						</div>
					</div>
				</div>
			</div>

			<div className="md:px-16 lg:px-28 px-8 mt-28  justify-center w-full h-full mx-auto">
				<div className="lg:flex md:grid grid lg:space-y-0 md:space-y-10 space-y-10 lg:gap-10">
					<div className="grid  lg:w-1/2 md:w-full w-full ">
						<p className=" text-homeSixCol lg:text-[13px] md:text-[10px] text-[10px] font-bold">
							WE ARE THE BEST SUBSCRIPTION PLATFORM
						</p>
						<p className=" lg:text-[24px] md:text-[18px] text-[15px] font-bold">
							We have the BEST Features For Your Subscriptions. You can trust us
							with your subscriptions.
						</p>
						<p>
							{" "}
							Take control of your financial future with our easy-to-use
							budgeting and friendly tools. Enjoy peace of mind with our 24/7
							customer support and expert guidance.
						</p>
						<p>
							Empower Your Financial Journey with the Ultimate Solution for
							Convenience and experience the ease and security of managing your
							finances with our innovative and comprehensive solution.
						</p>
						<div className=" object-cover">
							<img
								className=""
								src={require("../../Assets/HsixFrame.png")}
								alt=""
							/>
						</div>
					</div>

					<div className="grid lg:w-1/2 md:w-full w-full space-y-7">
						<div className=" object-cover">
							<img src={require("../../Assets/HsixFrame2.png")} alt="" />
						</div>
						<div className="flex space-x-5">
							<div>
								<img
									className="w-10 h-10"
									src={require("../../Assets/HsixGroup1.png")}
									alt=""
								/>
								<div>
									<p className=" text-[20px]">24/7 Customer Services</p>
									<p>
										{process.env.REACT_APP_AGENT_NAME} has a team of
										well-trained customer support agents available 24/7 to
										assist you with any issues you may have. We offer multiple
										channels of communication to ensure that you can easily
										reach us and receive prompt assistance.
									</p>
								</div>
							</div>
							<div>
								<img
									className="w-10 h-10"
									src={require("../../Assets/HsixGroup2.png")}
									alt=""
								/>
								<div>
									<p className=" text-[20px]">Safe and Convenient</p>
									<p>
										Periodically, {process.env.REACT_APP_AGENT_NAME} offers the
										best discounts and special deals on all of our services,
										including data, airtime, and cable subscriptions. Stay tuned
										for updates on our special offers and take advantage of the
										opportunity to save on these services.
									</p>
								</div>
							</div>
						</div>

						<button
							onClick={() => navigate("/login")}
							type="button"
							className=" transition-transform hover:scale-110 duration-300 ease-in text-white bg-homeSixCol lg:w-[170px] lg:h-[42px] md:w-[130px] md:h-[38px] w-[120px] h-[30px] rounded-3xl">
							Get Started
						</button>
					</div>
				</div>
			</div>

			<div className=" bg-homeSixBgCol">
				<div className=" md:px-16 lg:px-28 px-8 lg:mt-16 md:mt35 mt-20  justify-center w-full h-full mx-auto">
					<div className=" grid justify-center">
						<div className="py-20">
							<p className=" lg:text-[13px] md:text-[10px] text-[10px] text-homeSixCol text-center font-bold ">
								WHAT WE DO
							</p>
							<p className=" lg:text-[24px] md:text-[18px] text-[12px] w-2/3 flex justify-center mx-auto text-center">
								We have the BEST Features For Your Subscriptions. You can trust
								us with your subscriptions.
							</p>
						</div>

						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 justify-center mx-auto">
							{cardData.map((card, index) => (
								<HomeSixCard
									key={index}
									image={card.image}
									imagee={card.imagee}
									title={card.title}
									text={card.text}
									buttonText={card.buttonText}
									arrow={card.arrow}
									backgroundColor={
										backgroundColors[index % backgroundColors.length]
									}
								/>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className=" bg-homeSixTestiCol mx-auto">
				<div className="md:px-16 lg:px-28 px-8  justify-center w-full h-full mx-auto">
					<div className="lg:flex md:flex grid">
						<div className=" lg:w-1/2 md:w-full w-full">
							<img
								className=" object-cover w-auto h-96 relative lg:right-[-60px] md:right-[-90px] right-[-90px]"
								src={require("../../Assets/HsixSmiling.png")}
								alt=""
							/>
						</div>
						<div className=" lg:w-1/2">
							<div className="grid lg:space-y-5 ml-10 md:space-y-5 relative lg:bottom-[-30px] md:bottom-[-30px] ">
								<div className="flex lg:space-x-10 md:space-x-6 space-x-4  relative lg:left-[-50px] ">
									<img
										className=" w-auto lg:h-28  md:h-14 h-16 "
										src={require("../../Assets/HsixLine7.png")}
										alt=""
									/>
									<p className=" lg:text-[20px] text-white">
										I was hesitant to switch to an online platform for my
										subscriptions initially, but{" "}
										<span className=" text-homeSixCol lg:text-[20px]">
											{process.env.REACT_APP_AGENT_NAME}
										</span>{" "}
										has exceeded my expectations.
									</p>
								</div>

								<div className="grid relative lg:left-[-50px]">
									<p className=" text-[18px] text-white">Latest review</p>
									<div className="flex items-center">
										<img
											className="h-[48px] w-[192px]"
											src={require("../../Assets/HsixAvatar.png")}
											alt=""
										/>
										<span className=" text-[24px] text-white">20K</span>{" "}
										<span className=" text-homeSixCol text-[24px]">+</span>
									</div>
								</div>

								<div className="grid relative lg:left-[-50px]">
									<p className="text-[18px] text-white">Ratings</p>
									<div className=" flex items-center relative lg:top-[-25px] md:top-[-15px] top-[-25px]">
										<img
											className=" w-[152px] h-[24px]"
											src={require("../../Assets/HsixRating.png")}
											alt=""
										/>

										<p className=" text-[24px] text-white mt-[13px]">4.9</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="md:px-16 lg:px-28 px-8 mt-28  justify-center w-full h-full mx-auto">
				<div className=" grid justify-center items-center">
					<p className=" text-homeSixCol text-[13px] text-center">
						HEAR OUR CUSTOMERS SPEAKS
					</p>
					<p className="text-[24px] text-center w-[580px] font-bold">
						We have the BEST Features For Your Subscriptions. You can trust us
						with your subscriptions.
					</p>
				</div>
				{/* rectangles */}
			</div>

			{/* footer */}

			{/* <div className="bg-homeSixTestiCol mx-auto py-10">
				<div className="md:px-16 lg:px-28 px-8  justify-center w-full h-full mx-auto">
					<div className=" flex space-x-10">
						<img
							className="w-auto h-20 relative bottom-[-20px]"
							src={require("../../Assets/HsixLine7.png")}
							alt=""
						/>
						<div className="grid">
							<p className=" text-[16px] text-homeSixCol">JOIN OUR COMMUNITY</p>
							<p className="text-[24px] text-white w-80">
								{" "}
								Subscribe to Get Our Latest Updates
							</p>
						</div>

					</div>
				</div>
			</div> */}
			<div name="contact" className="bg-homeSixTestiCol mx-auto py-10">
				<div className="h-full px-8 md:px-16 lg:px-28 grid space-y-5 items-center py-10 bg-opacity-95 text-white">
					<div
						data-aos="fade-up"
						data-aos-duration="3000"
						className=" lg:flex md:grid grid lg:space-y-0 md:space-y-0 space-y-5 justify-between">
						<div className=" flex space-x-10">
							<img
								className="w-auto h-20 relative bottom-[-20px]"
								src={require("../../Assets/HsixLine7.png")}
								alt=""
							/>
							<div className="grid">
								<p className=" text-[16px] text-homeSixCol">
									JOIN OUR COMMUNITY
								</p>
								<p className="text-[24px] text-white w-80">
									{" "}
									Subscribe to Get Our Latest Updates
								</p>
							</div>

							{/* input and button */}
						</div>

						<div className=" flex justify-center space-x-3 items-center">
							<div>
								{/* <input
									className=" lg:w-[500px] text-black px-2 md:w-[200px] w-[200px] lg:h-[57px] md:h-[30px] h-[30px] rounded-lg"
									placeholder="Enter Your Email"
									type="text"
								/> */}
							</div>
							<div>
								<button
									onClick={
										process.env.REACT_APP_AGENT_WHATSAPP_GROUP
											? () => {
													window.open(
														process.env.REACT_APP_AGENT_WHATSAPP_GROUP,
														"_blank"
													);
											  }
											: null
									}
									className=" lg:text-[16px] lg:h-[57px] md:h-[30px] h-[30px] lg:w-[174px] md:w-[100px] w-[100px] bg-homeSixTestiCol rounded-lg transition-transform hover:scale-110 duration-300 ease-in">
									Subscribe Now
								</button>
							</div>
						</div>
					</div>

					<div
						data-aos="fade-up"
						data-aos-duration="3000"
						className=" lg:flex md:grid grid lg:space-y-0 md:space-y-5 space-y-5 justify-between">
						<div className="grid">
							<AppLogo type="dark" height="50px" />
							<div>
								<p>{process.env.REACT_APP_AGENT_ADDRESS}</p>
								<div className=" w-56">
									{/* <p>
											Lorem ipsum dolor sit amet consectetur. Eu ut egestas elit
											ut orci. Bibendum lobortis amet.
										</p> */}
								</div>
							</div>
						</div>
						<div className="grid">
							<div className="flex items-center space-x-3">
								<img
									className=" w-auto h-4 mb-3"
									src={require("../../Assets/HsixLine7.png")}
									alt=""
								/>
								<p className=" font-bold">Explore</p>
							</div>
							<div className=" space-y-4">
								<Link
									to="/"
									className="text-white block cursor-pointer no-underline">
									About
								</Link>
								<a
									href={process.env.REACT_APP_AGENT_WHATSAPP || ""}
									target={process.env.REACT_APP_AGENT_WHATSAPP ? "_blank" : ""}
									rel="noreferrer"
									className="text-white block cursor-pointer no-underline">
									Contact
								</a>
							</div>
						</div>
						<div className="grid">
							<div className="flex items-center space-x-3">
								<img
									className=" w-auto h-4 mb-3"
									src={require("../../Assets/HsixLine7.png")}
									alt=""
								/>
								<p className=" font-bold">Our Services</p>
							</div>
							<div>
								<p onClick={() => navigate("/login")} className="myCursor">
									Airtime Services
								</p>
								<p onClick={() => navigate("/login")} className="myCursor">
									Data services
								</p>
								<p onClick={() => navigate("/login")} className="myCursor">
									Cable Subscription
								</p>
								<p onClick={() => navigate("/login")} className="myCursor">
									TV Subscription
								</p>
							</div>
						</div>
						<div className="grid">
							<div className="flex items-center space-x-3">
								<img
									className=" w-auto h-4 mb-3"
									src={require("../../Assets/HsixLine7.png")}
									alt=""
								/>
								<p className="font-bold">Contact Us</p>
							</div>
							<div>
								{/* <p>+234 800 000 0000</p> */}
								<p>{process.env.REACT_APP_AGENT_TELEPHONE}</p>
								<p>{process.env.REACT_APP_AGENT_EMAIL}</p>
							</div>
						</div>
					</div>

					<div>
						<hr className=" text-white" />
					</div>

					<div className="lg:flex md:grid grid items-center justify-between">
						{/* <div className="flex items-center space-x-3">
								<img
									className=" w-5 h-5"
									src={require("../../Assets/HsevenInstagram.png")}
									alt=""
								/>
								<img
									className=" w-5 h-5"
									src={require("../../Assets/HsevenTwiter.png")}
									alt=""
								/>
								<img
									className=" w-5 h-5"
									src={require("../../Assets/HsevenFacebook.png")}
									alt=""
								/>
							</div> */}
						<ul className="list-group border-0 list-group-horizontal">
							{socials.map((item, index) => (
								<li
									key={index}
									className="list-group-item border-0 bg-transparent text-white d-flex align-items-center">
									<a
										className="text-white text-decoration-none fontReduce"
										target={"_blank"}
										rel="noreferrer"
										href={
											item?.type === "mail" && item?.url
												? `mailto:${item?.url}`
												: item?.url || "#"
										}>
										{item?.icon}
									</a>
								</li>
							))}
						</ul>
						<div>
							<p>Copyright © 2023. All rights reserved</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeSix;
