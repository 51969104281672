import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import HoneFirstcad from "../../Assets/HoneFirstcad.png";

import HomeTwelveNav from "../../Components/home-twelve/HomeTwelveNav";
import Htwelveline from "../../Assets/Htwelveline.png";
import Htwelveic from "../../Assets/Htwelveic.png";
import Htwelveic2 from "../../Assets/Htwelveic2.png";
import Htwelvedown from "../../Assets/Htwelvedown.png";
import AppLogo from "../../Components/app-logo";
import { socials } from "../../Components/Footer";
import { Link as RouterLink } from "react-router-dom";

const HomeTwelve = () => {
	const cardthree = [
		{
			image: Htwelvedown,

			text: ` Is ${process.env.REACT_APP_AGENT_NAME} Secure?`,
			id: 1,
		},
		{
			image: Htwelvedown,

			text: `What services does ${process.env.REACT_APP_AGENT_NAME} offer? `,
			id: 2,
		},
		{
			image: Htwelvedown,

			text: ` Is ${process.env.REACT_APP_AGENT_NAME} free to use?`,
			id: 3,
		},
		{
			image: Htwelvedown,

			text: `Can I use ${process.env.REACT_APP_AGENT_NAME} outside of the country? `,
			id: 4,
		},
	];

	const cardtwo = [
		{
			image: Htwelveic,
			heading: "Data Services",
			text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			id: 1,
			button: "Read more",
		},
		{
			image: Htwelveic2,
			heading: "Airtime Pin Service",
			text: `is a reliable and affordable platform for purchasing
									affordable data bundles and airtime top-ups for any Nigerian
									network. We offer competitive pricing for data plans from
									Airtel, MTN, 9mobile, and Glo`,
			id: 2,
			button: "Read more",
		},
		{
			image: Htwelveic2,
			heading: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			id: 3,
			button: "Read more",
		},
		{
			image: Htwelveic,
			heading: "Bill Payment",
			text: "Do you want to stay on top of your finances, then make you can make use of  our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools.",
			id: 4,
			button: "Read more",
		},
		{
			image: Htwelveic2,
			heading: "Cable Subscription",
			text: `At ${process.env.REACT_APP_AGENT_NAME}, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.`,
			id: 5,
			button: "Read more",
		},
		{
			image: Htwelveic2,
			heading: "Education",
			text: `${process.env.REACT_APP_AGENT_NAME}  is your go-to source for various examination scratch cards pins, including Waec, Neco, and Nabteb examinations`,
			id: 5,
			button: "Read more",
		},
	];

	const cards = [
		{
			image: HoneFirstcad,
			heading: "Secured Transaction",
			text: `We take the security of your account seriously, we are
										committed to preventing data loss or leak.`,
			id: 1,
			gradientClass: "bg-gradient-red",
		},
		{
			image: HoneFirstcad,
			heading: "Quick Delivery",
			id: 2,
			gradientClass: "bg-gradient-green",
			text: `Yes, we got you covered, With ${process.env.REACT_APP_AGENT_NAME}{" "}
              Enjoy a easy and fast data delivery with Optimal security and also
              enjoy the best offers and discount sales on all our products
              periodically.`,
		},
		{
			image: HoneFirstcad,
			heading: "Optimum Sastisfactions",
			text: `Periodically, ${process.env.REACT_APP_AGENT_NAME} offers the
										best discounts and special deals on all of our services,
										including data, airtime, and cable subscriptions. Stay tuned
										for updates on our special offers and take advantage of the
										opportunity to save on these services.`,
			id: 3,
			gradientClass: "bg-gradient-green",
			line: Htwelveline,
		},
		{
			image: HoneFirstcad,
			heading: "Exceptional Customer Support",
			text: `${process.env.REACT_APP_AGENT_NAME} has a team of
										well-trained customer support agents available 24/7 to
										assist you with any issues you may have. We offer multiple
										channels of communication to ensure that you can easily
										reach us and receive prompt assistance.`,
			id: 4,
			gradientClass: "bg-gradient-green",
		},
	];

	//   const cardtwo = [
	//     {
	//       images: Heleven15,
	//       headings: "Cable Subscriptions",
	//       texts:
	//         "At Cebizpay, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.",
	//     },
	//     {
	//       images: Heleven16,
	//       headings: "Electricity Purchase",
	//       texts:
	//         "Do you want to stay on top of your finances, then make you can make use of  our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools.",
	//     },
	//   ];
	return (
		<div className=" mx-auto overflow-x-hidden">
			<div className=" bg-[#F3F7FA]">
				<HomeTwelveNav />
				<div className="md:px-16 lg:px-28 px-8 lg:flex md:grid grid gap-20 md:gap-20 lg:gap-0 items-center lg:pt-28 md:pt-40 pt-28">
					<div
						data-aos="fade-right"
						data-aos-duration="3000"
						className=" lg:lg:w-1/2 md:w-full w-full grid gap-24">
						{" "}
						<div>
							{" "}
							<p className=" lg:w-[645px] md:w-[645px] w-full lg:text-[50px] md:text-[70px] text-[50px] font-bold font-roboto">
								Getting Bills Paid without Being Frustrated{" "}
							</p>{" "}
							<p className=" text-[14px] lg:w-[400px] md:w-[600px] w-full">
								Purchase internet data with just a few clicks. Choose from a
								variety of data plans for any network you prefer. Get Started!{" "}
							</p>{" "}
						</div>{" "}
						<div className="flex  gap-3">
							{" "}
							<RouterLink
								to="/register"
								className=" grid bg-[#7A0E0E] hover:scale-105 duration-300 ease-in px-3 items-center h-[66px] text-white font-roboto lg:text-[28px]">
								Get Started{" "}
								<div className="flex justify-center mt-[-15px]">
									{" "}
									<img
										className=" w-[54px]"
										src={require("../../Assets/HoneArrow.png")}
										alt=""
									/>
								</div>
							</RouterLink>
							<button className=" flex items-center gap-1">
								<p className=" bg-[#7A0E0E] flex items-center pl-8 h-[66px] text-white font-roboto lg:text-[28px]">
									Learn
								</p>
								<p className="flex text-[#7A0E0E] gap-2 items-center font-roboto lg:text-[28px]">
									more{" "}
									<img
										className=" lg:w-[54px] md:w-[50px] w-[44px]"
										src={require("../../Assets/HoneArrow2.png")}
										alt=""
									/>
								</p>
							</button>
						</div>
					</div>

					<div
						data-aos="fade-left"
						data-aos-duration="3000"
						className=" lg:w-1/2 md:w-full w-full">
						<img src={require("../../Assets/HoneHerro.png")} alt="" />
					</div>
				</div>
			</div>
			{/* Take Control Of Your Subscriptions with ${process.env.REACT_APP_AGENT_NAME}s */}
			<div className="grid md:px-16 lg:px-28 px-8 items-center py-16 justify-center mx-auto w-full">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid items-center justify-center">
					<p className=" text-center font-roboto text-[28px] lg:w-[520px] font-bold ">
						Take Control Of Your Subscriptions with
						{process.env.REACT_APP_AGENT_NAME}s
					</p>

					<p className=" text-center font-Alata text-[14px] lg:w-[550px] ">
						Take control of your financial future with our easy-to-use budgeting
						and friendly tools. Enjoy peace of mind with our 24/7 customer
						support and expert guidance.
					</p>
				</div>

				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-16">
					{cards.map(items => (
						<div
							key={items.id}
							className=" bg-transparent grid justify-center items-center">
							<div className=" flex items-center justify-center">
								<img className="w-[44.1px]" src={items.image} alt="" />
							</div>

							<p className=" underline text-[18px] font-bold font-Alata text-center">
								{items.heading}
							</p>
							<p className=" text-center">{items.text}</p>
							<img src={items.line} alt="" />
						</div>
					))}
				</div>
			</div>

			{/* Customers Reviews */}
			<div className=" bg-[#FDF3ED] md:px-16 lg:px-28 px-8 py-10">
				<div
					data-aos="fade-down"
					data-aos-duration="3000"
					className=" grid justify-center items-center">
					<p className=" font-bold text-[32px] font-roboto text-center">
						Customers Reviews
					</p>
					<p className=" text-center lg:w-[450px] md:w-[450px] w-full">
						Here what our customers have to say
					</p>
				</div>
				{/* testimonial cards */}
				<div className=" lg:flex md:grid grid justify-center gap-10 items-center pt-10">
					<div
						data-aos="fade-right"
						data-aos-duration="3000"
						className=" grid justify-center items-center">
						<div className=" bg-[#7A0E0E] py-2 rounded-md flex lg:w-[378.5px] md:w-[378.5px] w-full">
							<div className="  bg-[#F6F4F4] flex w-full rounded-md">
								<div className=" mt-10 ">
									<img
										className=" h-[100px] w-[15px]"
										src={require("../../Assets/Htwelveline2.png")}
										alt=""
									/>
								</div>
								<div className=" grid px-5 py-10 gap-10">
									<div className=" flex items-center gap-10">
										<img
											className=" w-[115px] h-[115px]"
											src={require("../../Assets/HtwelveT.png")}
											alt=""
										/>
										<div className="grid">
											<p>Tola Williams</p>
											<p>London</p>
										</div>
									</div>

									<div className=" h-[257px]">
										I enjoy excellent customer service and affordable telecom
										services with swift delivery daily while using this
										platform. If you ever think of making more profits in your
										data reselling business, think of{" "}
										{process.env.REACT_APP_AGENT_NAME}
									</div>
								</div>
								<div className=" grid justify-end items-end mb-10">
									<img
										className=" h-[100px] w-[15px]"
										src={require("../../Assets/Htwelveline2.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div
						data-aos="fade-left"
						data-aos-duration="3000"
						className=" grid items-center gap-5 ">
						<div className=" lg:flex md:flex grid items-center gap-3">
							{/* 1st */}
							<div className="grid bg-[#F1F1F1] lg:w-[322px] md:w-[322px] w-full p-4 rounded-md">
								<img
									className=" w-[21px] h-[19px]"
									src={require("../../Assets/Htwelve“.png")}
									alt=""
								/>
								<div className=" flex items-center gap-10">
									<img
										className=" w-[104px] h-[104px]"
										src={require("../../Assets/HtwelveT.png")}
										alt=""
									/>
									<div className="grid">
										{/* <p>Lorem ipsum dol sit amet conse</p> */}
										<p className=" font-bold font-sans">Tola Williams</p>
									</div>
								</div>
							</div>
							{/* sec */}
							<div className="grid bg-[#F1F1F1] lg:w-[322px] md:w-[322px] w-full p-4 rounded-md">
								<img
									className=" w-[21px] h-[19px]"
									src={require("../../Assets/Htwelve“.png")}
									alt=""
								/>
								<div className=" flex items-center gap-10">
									<img
										className=" w-[104px] h-[104px]"
										src={require("../../Assets/HtwelveT.png")}
										alt=""
									/>
									<div className="grid">
										{/* <p>Lorem ipsum dol sit amet conse</p> */}
										<p className=" font-bold font-sans">Tola Williams</p>
									</div>
								</div>
							</div>
						</div>

						{/* button */}
						<div className=" flex justify-end items-center">
							<button className=" w-[129px] h-[33px] rounded-full border-2 border-[#7A0E0E] text-[#7A0E0E] font-bold">
								Read more
							</button>
						</div>

						<div className=" flex bg-[#7A0E0E] text-white justify-center lg:gap-20 md:gap-20 gap-3 rounded-md py-4">
							<div className=" flex items-center lg:pl-0 md:pl-0 pl-3 gap-2">
								<img
									className="w-[55px] h-[55px]"
									src={require("../../Assets/Htwelveuser.png")}
									alt=""
								/>
								<div className="grid">
									<p className=" font-Alata font-bold">+900K</p>
									<p>Customers</p>
								</div>
							</div>
							<div>
								<img
									className=" h-[100px]"
									src={require("../../Assets/HtwelveVector2.png")}
									alt=""
								/>
							</div>

							<div className=" flex items-center lg:pr-0 md:pr-0 pr-3 gap-2">
								<img
									className="w-[55px] h-[55px] z-10"
									src={require("../../Assets/Htwelveuser.png")}
									alt=""
								/>
								<div className="">
									<p className=" font-Alata font-bold">+900K</p>
									<p>Customers</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* section four */}
			<div className="md:px-16 lg:px-28 px-8 py-10">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid items-center justify-center pb-10">
					<div className=" flex justify-center">
						<div className=" flex ">
							<div className=" flex absolute">
								<p className=" text-[32px] font-roboto relative top-10">
									Services
								</p>
							</div>

							<p className=" text-[64px] text-gray-300 font-bold font-roboto">
								Services
							</p>
						</div>
					</div>

					{/* <p className=" text-center w-[400px]">
						Lorem ipsum dolor sit amet elementumeget augue ac volut pat
						.consectetur. Nec{" "}
					</p> */}
				</div>

				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
					{cardtwo.map(items => (
						<div
							key={items.id}
							className=" border-2 border-gray-300 lg:w-[313.74px] grid justify-center gap-3 p-4 items-center rounded-md">
							<div className=" flex justify-center items-center">
								<img
									className=" w-[57.17px] h-[54.45px]"
									src={items.image}
									alt=""
								/>
							</div>

							<p className=" text-center underline font-bold font-roboto text-[18px]">
								{items.heading}
							</p>
							<p className=" text-center">{items.text}</p>
							<div className=" flex justify-center items-center">
								<button className=" text-white bg-[#7A0E0E] w-[114.09px] h-[41.49px] underline">
									{items.button}
								</button>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Frequently Asked Questions */}

			<div className="md:px-16 lg:px-28 px-8 pt-10 lg:pb-40 md:pb-10 pb-10 bg-[#FDF3ED]">
				<div
					data-aos="fade-down"
					data-aos-duration="3000"
					className=" grid justify-center items-center">
					<p className=" font-roboto text-[36px] font-bold text-center">
						Frequently Asked Questions
					</p>
					<p className=" text-center">
						Everything you need to know about Billing and Product
					</p>
				</div>

				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" lg:py-0 md:py-0 py-2">
					<p className=" text-[20px] font-bold font-roboto">
						What is {process.env.REACT_APP_AGENT_NAME}?
					</p>
					{/* <p>
						Lorem ipsum dolor sit amet consectetur. Ac mi mauris nunc dapibus
						donec condimentum varius. Turpis mattis quisque velit donec tellus
						bibendum diam. Ullamcorper fames elementum id hac lacus imperdiet
						magna facilisi adipiscing. Facilisis ridiculus lectus lacus mattis
						varius purus non tellus.
					</p> */}
				</div>
				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" gap-2 grid">
					{cardthree.map(items => (
						<div
							key={items.id}
							className=" flex items-center justify-between bg-[#DADADA] py-3 px-3">
							<p className=" text-[20px]">{items.text}</p>

							<img
								className=" w-[13.14px] h-[7.1px]"
								src={items.image}
								alt=""
							/>
						</div>
					))}
				</div>
			</div>

			{/* We are Trusted and Tested by +1000k Companies */}

			<div className="md:px-16 lg:px-28 px-8 bg-[#F8FAFB] items-center lg:py-0 md:py-10 py-10 justify-center  mx-auto w-full">
				<div className=" justify-center grid items-center lg:relative bottom-12">
					<div
						data-aos="fade-right"
						data-aos-duration="3000"
						className=" bg-[#7A0E0E] lg:w-[900px] md:w-[700px] text-white lg:flex md:flex grid  px-5 py-4 items-center justify-between px">
						<p className=" lg:text-[28px] md:text-[20px] text-[18px]">
							We are Trusted and Tested by +1000k Companies
						</p>
						<button className=" border-2 border-white rounded-full px-4 py-2">
							View more
						</button>
					</div>
				</div>

				<div className="overflow-hidden py-10">
					<div className="flex animate-marquee gap-5 items-center">
						<img
							className=" lg:h-[48px] lg:w-[150px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/HtwelveTechac.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[150px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Htwelvecool.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[150px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/HtwelveTechac.png")}
							alt=""
						/>

						<img
							className=" lg:h-[48px] lg:w-[150px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Htwelvecool.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			{/* footer */}
			<div className=" bg-[#FDF3ED]  text-black font-Satoshi">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className="md:px-16 lg:px-28 px-8 py-10">
					<div className=" lg:flex md:flex grid justify-between">
						<div className=" grid gap-3 ">
							<div className=" text-lg font-semibold flex items-center gap-2">
								{/* <img
									className=" w-[40px ] h-[40px]"
									src={require("../../Assets/Homeonelogo.png")}
									alt=""
								/> */}
								<AppLogo height={"50px"} />
								<p className=" font-bold text-[24px] font-ZenDots mt-4">
									{process.env.REACT_APP_AGENT_NAME}
								</p>
							</div>
							<div className=" lg:w-[250px]">
								{/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
							</div>
							{/* <div className=" flex gap-3 items-center">
								<div className=" bg-[#E67E33] w-[26px] h-[26px] rounded-full"></div>
								<div className=" bg-[#E67E33] w-[26px] h-[26px] rounded-full"></div>
								<div className=" bg-[#E67E33] w-[26px] h-[26px] rounded-full"></div>
							</div> */}
							<ul className="list-group border-0 list-group-horizontal">
								{socials.map((item, index) => (
									<li
										key={index}
										className="list-group-item border-0 bg-transparent text-[#E67E33] d-flex align-items-center">
										<a
											className="text-[#E67E33] text-decoration-none fontReduce"
											target={"_blank"}
											rel="noreferrer"
											href={
												item?.type === "mail" && item?.url
													? `mailto:${item?.url}`
													: item?.url || "#"
											}>
											{item?.icon}
										</a>
									</li>
								))}
							</ul>
							<p className="lg:text-[22px] md:text-[20px] text-[20px] font-bold">
								Contact Us
							</p>
							<div>
								{/* <p>+234 800 000 0000</p> */}
								<p>{process.env.REACT_APP_AGENT_TELEPHONE}</p>
								<p>{process.env.REACT_APP_AGENT_EMAIL}</p>
							</div>
						</div>
						<ul className="grid lg:mt-0 md:mt-0 mt-10">
							<p className=" text-[16px] font-bold">Product</p>
							<li className=" text[16px]">Download </li>
							<li className=" text[16px]">Pricing</li>
							<li className=" text[16px]">Locations</li>
						</ul>
						<ul className="grid">
							<p className=" text-[16px] font-bold">Company</p>
							<li className=" text[16px]">Brand</li>
							<li className=" text[16px]">FAQ</li>
							<li className=" text[16px]">Tutorials</li>
						</ul>
						<ul className="grid">
							<p className=" text-[16px] font-bold">Privacy</p>
							<li className=" text[16px]">Privacy</li>
							<li className=" text[16px]">Terms of Use</li>
							<li className=" text[16px]">Site Map</li>
						</ul>
					</div>
					<div className=" lg:flex md:grid grid justify-between items-center mt-5">
						<div>
							<p className=" text-[16px]">
								All rights reserved.
								<span className="mx-1">
									&copy;{" "}
									{`${new Date().getFullYear() !== 2023 ? "2023 - " : ""}`}
									{new Date().getFullYear()}
								</span>
								&nbsp;{" "}
								<span className="d-block d-lg-inline">
									{process.env.REACT_APP_AGENT_NAME}{" "}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeTwelve;
