import React, { useContext, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
	Header,
	Sidebar,
	DefaultHeader,
	Footer,
	SideHeader,
	ModalComponents,
} from "./Components";
import { GlobalState } from "./Data/Context";
import PageRender from "./PageRender";
import gif from "./Assets/59945-success-confetti.gif";
import gif2 from "./Assets/icons8-cancel.gif";
import moment from "moment";
import { Fragment } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

import Home from "./Screens/home";
import Home2 from "./Pages/home";
import Home3 from "./Views/home";
import HomeFour from "./Screens/home-four";
import HomeThree from "./Screens/home-three";
import HomeTwo from "./Screens/home-two/index";
import HomeOne from "./Screens/home-one/index";

import HomeFive from "./Screens/home-five";
import HomeSix from "./Screens/home-six";
import HomeSeven from "./Screens/home-seven";
import HomeEight from "./Screens/home-eight";
import HomeNine from "./Screens/home-nine";
import HomeTen from "./Screens/home-ten";
import HomeEleven from "./Screens/home-eleven";
import HomeTwelve from "./Screens/home-twelve";
import HomeThirteen from "./Screens/home-thirteen";
import HomeFourteen from "./Screens/home-fourteen";
import HomeFifteen from "./Screens/home-fifteen";

const Routers = () => {
	const {
		auth,
		success,
		restoreMsg,
		errors,
		clearErrors,
		notifications,
		manageNotify,
	} = useContext(GlobalState);

	let [notifyList, setNotifyList] = useState([]),
		[isOpen, setIsOpen] = useState(false),
		[keepOpen, setKeepOpen] = useState("open");

	useEffect(() => {
		if (notifications?.incoming?.length > 0) {
			let res = notifications?.incoming?.filter(
				item => item?.priority && !item?.isNotifiedTo?.includes(auth?.user?._id)
			);
			if (res?.length > 0) setNotifyList(res);
		}
	}, [notifications?.incoming, auth?.user]);

	useEffect(() => {
		if (notifyList?.length > 0 && !isOpen && keepOpen === "open") {
			setTimeout(() => {
				setIsOpen(true);
			}, 5000);
		} else {
			setIsOpen(false);
		}
		if (notifyList?.length === 0 || keepOpen !== "open") setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifyList?.length, keepOpen]);

	let markAsNotified = async () => {
		for (let m = 0; m < notifyList.length; m++) {
			await manageNotify(null, notifyList?.[m]?._id, "prior");
		}
		setIsOpen(false);
	};

	return (
		<>
			<ToastContainer autoClose={false} />
			{auth?.user ? (
				<>
					<Sidebar />
					<SideHeader noLogo />
				</>
			) : ["Honour World", "Home eight", "Home eleven"]?.includes(
					process.env.REACT_APP_AGENT_NAME
			  ) ||
			  [
					"Spike Mobile",
					"Teetop Digital",
					"Vickybest Telecom",
					"Kemtech Enterprises",
					"Fantastic Fisayo",
					"Mel Data Services",
					"Yema Telecommunications",
					"V12 Pay",
					"Allbrite Digitals",
					"Noney Data Plug",
			  ]?.includes(process.env.REACT_APP_PROVIDER_NAME) ||
			  process.env.REACT_APP_TEMPLATE ? (
				<></>
			) : (
				<Header />
				// ""
			)}
			<div className={auth?.user ? "home" : ""}>
				{auth?.user ? <DefaultHeader /> : <></>}
				<Routes>
					<Route
						path="/"
						element={
							["agent", "superadmin"]?.includes(auth?.user?.privilege) ? (
								<Home3 />
							) : auth?.user ? (
								<Home2 />
							) : [
									"Home fifteen",
									"JJ Telecom",
									"Charris Global",
									"Iphie Telecom",
									"Chi's Best Telecom",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Fifteen" ? (
								<HomeFifteen />
							) : [
									"Home fourteen",
									"Her Prime Digitals",
									"Favvy Telecom Services",
									"Easyway Telecom",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Fourteen" ? (
								<HomeFourteen />
							) : [
									"Prevail Telecoms",
									"Home thirteen",
									"ARA VENTURES",
									"Deravee Telecom",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Thirteen" ? (
								<HomeThirteen />
							) : [
									"Home twelve",
									"Vv Group",
									"VickySpeaks Telecom Services",
									"CHRISLOVE TELECOM SERVICES",
									"Sommyluv Telecom",
									"Amas Telecommunication Services",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Twelve" ? (
								<HomeTwelve />
							) : [
									"Home eleven",
									"Neche Divine Telecom",
									"Ifybest Telecom",
									"Noney Data Plug",
									"AmaraBestDataPlug",
									"Lidy Telecom",
									"PrOma Networks",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Eleven" ? (
								<HomeEleven />
							) : [
									"Home ten",
									"Vickybest Telecom",
									"Fantastic Fisayo",
									"Marvelgreat",
									"Earnest Telecom",
									"Josh Data",
									"Mercy Telecoms",
									"Pearl Telecom",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Ten" ? (
								<HomeTen />
							) : [
									"Home nine",
									"Risez Tel",
									"Deravine telecom ",
									"M.O.T Global Services",
									"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
									"SOLEX DIGITAL",
									"Vee's Telecom",
									"Zadapay",
									"FRANCA DATA SERVICES",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Nine" ? (
								<HomeNine />
							) : [
									"Home eight",
									"Rosie Telecommunications",
									"PATOLEX MEDIA",
									"Puritech Data Services",
									"GOODY TELECOM HUB",
									"DIVA GLOBAL TECHNOLOGIES",
									"Prisca's Telecom",
									"SAMTECH TELECOM",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Eight" ? (
								<HomeEight />
							) : ["Ada Telecoms", "Crimson Tag"]?.includes(
									process.env.REACT_APP_AGENT_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Seven" ? (
								<HomeSeven />
							) : [
									"Lousattraction Telecom",
									"MyTelecom",
									"Father's Joy Telecoms",
									"OLA DATA TELECOM",
									"Miracle Data Service",
									"Deblinks Telecom",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Six" ? (
								<HomeSix />
							) : [
									"Uptown Telecoms",
									"FetB Telecom",
									"Pistis Telecom",
									"Bethel Telecom",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Five" ? (
								<HomeFive />
							) : [
									"DANESTY",
									"Cindy Digital",
									"Testimony101",
									"Tkonnect Telecom",
									"Rosyjul Communications",
									"GenTony Digitals",
									"Rightchoice",
									"Joyce Telecom",
									"Triumphant Network Telecommunication",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Four" ? (
								<HomeFour />
							) : [
									"Ellacruz",
									"Bliz",
									"Connect Solutions",
									"Giwa Digital",
									"Royal Data Services",
									"Divine Telecom Service",
									"HAYTOP TELECOMS",
									"Iyanu Data Services",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template One" ? (
								<HomeOne />
							) : [
									"Spike Mobile",
									"Vickybest Telecom",
									"Kemtech Enterprises",
									"Mel Data Services",
									"Allbrite Digitals",
									"Noney Data Plug",
							  ]?.includes(process.env.REACT_APP_PROVIDER_NAME) ||
							  [
									"ASIKA LINKS Telecom",
									"SUCCESSINDEED DATA VENDOR",
									"Best Data Plug",
									"Jahriyah Telecom",
									"Josh Telecom Services",
									"Chommybest Data Hub",
									"Oma Telecom",
									"PERETZ TELECOM",
									"TRACY TELECOM",
									"Iswell Telecom",
									"GloxyBless Data Service",
									"Isaac Data Service",
									"BILL TOP",
							  ]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Three" ? (
								<HomeThree />
							) : // <HomeTwo />
							["Honour World"]?.includes(process.env.REACT_APP_AGENT_NAME) ||
							  ["Teetop Digital", "V12 Pay"]?.includes(
									process.env.REACT_APP_PROVIDER_NAME
							  ) ||
							  process.env.REACT_APP_TEMPLATE === "Template Two" ? (
								// <HomeOne />
								<HomeTwo />
							) : (
								<Home />
							)
						}
					/>
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</div>
			{auth?.user ||
			["Honour World", "Home eight", "Home eleven", "Home twelve"]?.includes(
				process.env.REACT_APP_AGENT_NAME
			) ||
			[
				"Spike Mobile",
				"Teetop Digital",
				"Vickybest Telecom",
				"Kemtech Enterprises",
				"Fantastic Fisayo",
				"Mel Data Services",
				"Yema Telecommunications",
				"V12 Pay",
				"Allbrite Digitals",
				"Noney Data Plug",
			]?.includes(process.env.REACT_APP_PROVIDER_NAME) ||
			process.env.REACT_APP_TEMPLATE ? (
				<></>
			) : (
				<Footer />
				// ""
			)}
			<ModalComponents
				isOpen={success?.msg ? true : false}
				title="Success"
				size={"sm"}
				success="text-success text-succcess2"
				borderNone={"borderNone"}
				toggle={() => restoreMsg()}>
				<div className="downH2 d-flex flex-column">
					<div className="mx-auto">
						<img src={gif} alt="Gif" className="img-fluid" />
					</div>
					<p className="fw-bold Lexend text-center w-100">{success?.msg}</p>
					<button
						onClick={() => restoreMsg()}
						className="btn btn-success2 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5">
						close
					</button>
				</div>
			</ModalComponents>
			<ModalComponents
				isOpen={errors?.error?.error?.length > 0}
				title="Error"
				size={"sm"}
				success="text-danger text-danger2"
				borderNone={"borderNone"}
				toggle={() => clearErrors()}>
				<div className="downH2 d-flex flex-column">
					<div className="mx-auto mb-3">
						<img src={gif2} alt="Gif" className="img-fluid" />
					</div>
					{errors?.error?.error?.map((item, i) => (
						<p key={i} className="fw-bold Lexend text-center w-100">
							<span className="fontInherit me-2">{i > 0 && <>{i + 1}.</>}</span>{" "}
							{item?.msg}
						</p>
					))}
					<button
						onClick={() => clearErrors()}
						className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5">
						close
					</button>
				</div>
			</ModalComponents>
			<ModalComponents
				isOpen={isOpen}
				title="Be informed"
				size={"sm"}
				borderNone={"borderNone"}
				toggle={() => {
					setKeepOpen("close");
					markAsNotified();
				}}>
				<div className="downH2 d-flex flex-column">
					{notifyList?.map((item, i) => (
						<Fragment key={i}>
							<p className="fw-bold Lexend text-center w-100 my-0">
								<span className="fontInherit me-2">
									{i > 0 && <>{i + 1}.</>}
								</span>{" "}
								{item?.message}
							</p>
							<small className="d-block ms-auto Lexend mb-3">
								{moment(item?.createdAt).diff(moment(), "years") < 0
									? moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")
									: moment(item?.createdAt).diff(moment(), "months") < 0
									? moment(item?.createdAt).format("DD/MM hh:mm A")
									: moment(item?.createdAt).diff(moment(), "days") < 0
									? moment(item?.createdAt).format("DD/MM hh:mm A")
									: moment(item?.createdAt).format("hh:mm A")}
							</small>
						</Fragment>
					))}
					<button
						onClick={() => {
							setKeepOpen("close");
							markAsNotified();
						}}
						className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5">
						close
					</button>
				</div>
			</ModalComponents>
			{auth?.isAuth && (
				<div className="position-relative">
					<div
						className="position-absolute"
						style={{
							bottom: "2rem",
							right: "2rem",
						}}>
						{process.env.REACT_APP_AGENT_YOUTUBE && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#d63384",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_YOUTUBE}
								target={"_blank"}
								title="Visit Youtube Channel"
								rel={"noreferrer"}>
								<BsYoutube size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_TWITTER && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#3199b7",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_TWITTER}
								target={"_blank"}
								title="Contact admin through Twitter"
								rel={"noreferrer"}>
								<BsTwitter size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_INSTAGRAM && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#ff476b",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_INSTAGRAM}
								target={"_blank"}
								title="Contact admin through Instagram"
								rel={"noreferrer"}>
								<BsInstagram size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_FACEBOOK && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#1a10c5",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_FACEBOOK}
								target={"_blank"}
								title="Contact admin through Facebook"
								rel={"noreferrer"}>
								<FaFacebookF size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_WHATSAPP_GROUP && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#20c997",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_WHATSAPP_GROUP}
								target={"_blank"}
								title="Join WhatsApp Group"
								rel={"noreferrer"}>
								<FaWhatsapp size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_WHATSAPP && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#20c997",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_WHATSAPP}
								target={"_blank"}
								title="Contact admin through WhatsApp"
								rel={"noreferrer"}>
								<FaWhatsapp size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_EMAIL && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#d63384",
								}}
								title="Contact admin through email"
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={`mailto:${process.env.REACT_APP_AGENT_EMAIL}`}
								target={"_blank"}
								rel={"noreferrer"}>
								<HiOutlineEnvelope size={24} />
							</a>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Routers;
