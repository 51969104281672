import React from "react";

const HomeEightCard = ({
  image,
  title,
  text,

  backgroundColor,
}) => {
  const cardStyle = {
    backgroundColor: backgroundColor,
    width: "340px",
    height: "auto",
  };

  const imageStyle = {
    width: "62.72px",
    height: "auto",
  };
  return (
    <div
      className="card rounded-lg px-3 transition-transform hover:scale-105 duration-300 ease-in "
      style={cardStyle}
    >
      <div className=" grid mt-8 justify-center">
        <img
          className=" rounded-t-lg"
          src={image}
          alt={title}
          style={imageStyle}
        />
      </div>

      <div className="grid justify-center">
        <h4 className="mt-3 text-black text-center font-bold">{title}</h4>

        <div className="">
          <p className=" text-black">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeEightCard;
