import React from "react";
import { useState } from "react";
import { FaHamburger } from "react-icons/fa";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import AppLogo from "../app-logo";

const HomeElevenNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
		<nav className="fixed top-[-3px] left-0 right-0 z-20 bg-transparent py-5  px-8 h-20 md:px-16 lg:px-28 flex items-center justify-between">
			<div className="text-black text-lg font-semibold flex items-center">
				<AppLogo height={"50px"} />
				<p className=" font-bold text-[24px] font-sansation pt-2">
					{process.env.REACT_APP_AGENT_NAME}
				</p>
			</div>
			<div className="hidden md:flex space-x-10">
				<Link
					to="home"
					smooth={true}
					className="text-black cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in">
					Home
				</Link>
				<Link
					to="about"
					smooth={true}
					className="text-black cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in">
					About
				</Link>
				<Link
					to="service"
					smooth={true}
					className="text-black cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in">
					Our Service
				</Link>
			</div>
			<div>
				<RouterLink
					to="/register"
					className="px-10 py-2 lg:block md:block hidden bg-white rounded-3xl text-[#AD1DF1] border-[#AD1DF1] border-2 no-underline transition-transform hover:scale-110 duration-300 ease-in">
					Login
				</RouterLink>
			</div>
			<div className="md:hidden">
				<button
					onClick={() => setMenuOpen(!menuOpen)}
					className="text-black focus:outline-none ">
					<FaHamburger />
				</button>
			</div>
			{menuOpen && (
				<div className="md:hidden absolute top-16 left-0 w-full bg-white p-4 space-y-8">
					<Link
						to="/"
						onClick={closeMenu}
						className="text-black block cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in">
						Home
					</Link>
					<Link
						to="/about"
						onClick={closeMenu}
						className="text-black block cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in">
						About
					</Link>
					<Link
						to="/service"
						onClick={closeMenu}
						className="text-black block cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in">
						Our Service
					</Link>
					<RouterLink
						to="/login"
						onClick={closeMenu}
						className="px-4 py-2 lg:hidden grid justify-center font-bold md:hidden cursor-pointer bg-white rounded-3xl text-[#AD1DF1] border-[#AD1DF1] border-2 no-underline transition-transform hover:scale-110 duration-300 ease-in">
						Login
					</RouterLink>
				</div>
			)}
		</nav>
	);
};

export default HomeElevenNav;
