import React from "react";
import HomeElevenNav from "../../Components/home-eleven/HomeElevenNav";
import { Link as RouterLink } from "react-router-dom";
import HelevenAirtime from "../../Assets/HelevenAirtime.png";
import Heleven13 from "../../Assets/Heleven13.png";
import Heleven14 from "../../Assets/Heleven14.png";
import Heleven15 from "../../Assets/Heleven15.png";
import Heleven16 from "../../Assets/Heleven16.png";
import { socials } from "../../Components/Footer";
import AppLogo from "../../Components/app-logo";

const HomeEleven = () => {
	const cards = [
		{
			image: HelevenAirtime,
			heading: "Airtime Purchase",
			text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			id: 1,
			gradientClass: "bg-gradient-red",
		},
		{
			image: Heleven13,
			heading: "Data Purchase",
			text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			id: 2,
			gradientClass: "bg-gradient-green",
		},
		{
			image: Heleven14,
			heading: "Education",
			text: `${process.env.REACT_APP_AGENT_NAME}  is your go-to source for various examination scratch cards pins, including Waec, Neco, and Nabteb examinations`,
		},
	];

	const cardtwo = [
		{
			images: Heleven15,
			headings: "Cable Subscriptions",
			texts: `At ${process.env.REACT_APP_AGENT_NAME}, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.`,
		},
		{
			images: Heleven16,
			headings: "Electricity Purchase",
			texts:
				"Do you want to stay on top of your finances, then make you can make use of  our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools.",
		},
	];
	return (
		<div className=" mx-auto">
			<div name="/" className=" bg-[#F3F7FA]">
				<HomeElevenNav />
				<div className="md:px-16 lg:px-28 px-8 lg:pt-5 md:pt-32 pt-20">
					<div className=" lg:flex md:grid grid justify-between gap-10 items-center py-10">
						<div className=" grid lg:w-1/2 w-full justify-center items-center">
							<div>
								<p className=" font-sansation lg:text-[65px] md:text-[65px] text-[44px] text-black">
									Transform your{" "}
									<span className="font-sansation lg:text-[65px] md:text-[65px] text-[44px] text-[#AD1DF1]">
										digital financial life
									</span>{" "}
									with US!
								</p>
							</div>
							<div>
								<p className=" font-sansation text-[18px]">
									Purchase internet data with just a few clicks. Choose from a
									variety of data plans for any network you prefer. Get Started!
								</p>
							</div>

							<div className=" flex items-center mt-5">
								<RouterLink
									to="/login"
									className="px-4 py-2 text-white flex justify-center items-center no-underline rounded-full font-Nunito bg-[#AD1DF1]">
									Get Sterted
								</RouterLink>
								<RouterLink
									to="/login"
									className="px-4 py2 font-Nunito justify-center items-center no-underline flex">
									<img
										className=" w-[24px] h-[24px] animate-pulse"
										src={require("../../Assets/Demo.png")}
										alt=""
									/>
									<p className=" relative bottom-[-7px]">Watch Demo</p>
								</RouterLink>
							</div>
						</div>
						<div className=" grid lg:w-1/2 w-full justify-center items-center">
							<img
								className=" w-auto lg:h-[579.44px] md:h-[579.44px] h-[379.44px]"
								src={require("../../Assets/HelevenHero.png")}
								alt=""
							/>
						</div>
					</div>
				</div>
				<div className=" lg:grid justify-end  items-center md:hidden hidden ">
					<div className=" w-[290px] h-auto flex z-50 justify-center items-center bg-white p-2 gap-2 text-black fixed right-0 top-[160px] rounded-l-md  ">
						<div>
							<img
								className=" w-[161px] h-[112px]"
								src={require("../../Assets/Helevencon.png")}
								alt=""
							/>
						</div>
						<div className="grid gap-2 items-center">
							<div className=" flex items-center gap-2">
								<img
									className=" w-[70px] h-[70px]"
									src={require("../../Assets/Helevenpas.png")}
									alt=""
								/>
								<p>10% Connection Growth</p>
							</div>
							<div className=" flex gap-2 justify-center">
								<div>
									<img
										className=" w-[15px] h-[16px]"
										src={require("../../Assets/HelevenVector.png")}
										alt=""
									/>
								</div>
								<div>
									<p className="">Chichi Ifeanyi</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="grid md:px-16 lg:px-28 px-8 items-center lg:mt-16  md:mt35 mt-12 justify-center mx-auto w-full">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className="grid mt-10">
					<p className=" lg:text-[45px] md:text-[30px] text-[25px] text-center font-bold">
						Optimize Your Business Fast And Secured!
					</p>
					<div className=" grid justify-center items-center ">
						<p className=" text-center py-4 text-[16px] lg:w-[500px]">
							Boost your business fast and keep it securely optimized with our
							efficient data services!
						</p>
					</div>

					<div className="flex items-center justify-center">
						<RouterLink
							to="/login"
							className=" transition hover:scale-110 duration-300 ease-in grid justify-center items-center 
              no-underline  text-white bg-[#AD1DF1] lg:w-[170px] lg:h-[42px] md:w-[130px] md:h-[38px] 
              w-[120px] h-[30px] rounded-3xl">
							Get Started
						</RouterLink>
					</div>
				</div>
			</div>

			<div className="md:px-16 lg:px-28 px-8 bg-[#F8FAFB] py-10 items-center lg:mt-20 md:mt35 mt-24 justify-center mx-auto w-full">
				<div className="overflow-hidden">
					<div className="flex animate-marquee gap-5 items-center">
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Canva.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[150px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Expedia.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Adobe.png")}
							alt=""
						/>

						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Airbnb.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Amazon.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			<div
				name="about"
				className="md:pl-16 lg:pl-28 px-8 pt-20 lg:flex md:flex grid items-center justify-center  ">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid lg:w-3/4 md:w-3/4 w-full ">
					<p className=" lg:text-[42px] md:text-[32px] text-[20px] lg:w-[506px] font-Satoshi font-bold">
						Unlimited Benefits You Gain With US
					</p>
					<p className="text-[14px] lg:w-[400px] font-Alata">
						Unlock a world of unlimited benefits with us! Our services empower
						your business, providing fast and secure solutions. From seamless
						data access to efficient optimization, we've got you covered.
						Experience the advantage of a reliable partner, ensuring your
						success every step of the way. Choose us for unparalleled support
						and watch your business thrive.
					</p>
					<div className=" flex flex-wrap font-Satoshi gap-10">
						<div className=" shadow-lg bg-white rounded-lg px-4 py-2">
							<div className=" flex items-center gap-4">
								<div>
									<img
										className=" w-[40px] h-[40px]"
										src={require("../../Assets/Heleven1.png")}
										alt=""
									/>
								</div>
								<div>
									<p>Secure Transactions</p>
								</div>
							</div>
						</div>
						<div className=" shadow-lg bg-white rounded-lg px-4 py-2">
							<div className=" flex items-center gap-4">
								<div>
									<img
										className=" w-[40px] h-[40px]"
										src={require("../../Assets/Heleven2.png")}
										alt=""
									/>
								</div>
								<div>
									<p> Fast Delivery/Response</p>
								</div>
							</div>
						</div>
						<div className=" shadow-lg bg-white rounded-lg lg:w-[340px] px-4 py-2">
							<div className=" flex items-center gap-4">
								<div>
									<img
										className=" w-[40px] h-[40px]"
										src={require("../../Assets/Heleven3.png")}
										alt=""
									/>
								</div>
								<div>
									<p> Exceptional Customer Support</p>
								</div>
							</div>
						</div>
						<div className=" shadow-lg bg-white rounded-lg lg:w-[340px] px-4 py-2">
							<div className=" flex items-center gap-4">
								<div>
									<img
										className=" w-[40px] h-[40px]"
										src={require("../../Assets/Heleven4.png")}
										alt=""
									/>
								</div>
								<div>
									<p> Optimum Satisfaction</p>
								</div>
							</div>
						</div>
						<div className=" shadow-lg bg-white rounded-lg lg:w-[430px] px-4 py-2">
							<div className=" flex items-center gap-4">
								<div>
									<img
										className=" w-[40px] h-[40px]"
										src={require("../../Assets/Heleven6.png")}
										alt=""
									/>
								</div>
								<div>
									<p> 24/7 Customer Support </p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" lg:w-2/6 md:w-2/6 w-ful space-y-10 lg:mt-0 md:mt-0 mt-10">
					{/* connector growth1 */}
					<div className="grid justify-end items-center ml-[-50px] ">
						<div className=" w-[290px] h-auto flex justify-center items-center bg-white p-2 gap-2 text-black rounded-l-md  ">
							<div>
								<img
									className=" w-[161px] h-[112px]"
									src={require("../../Assets/Heleven9.png")}
									alt=""
								/>
							</div>
							<div className="grid gap-2 items-center">
								<div className=" flex items-center gap-2 ">
									<img
										className=" w-[70px] h-[70px]"
										src={require("../../Assets/Helevenpas2.png")}
										alt=""
									/>
									<p>10% Connection Growth</p>
								</div>
								<div className=" flex gap-2 justify-center">
									<div>
										<img
											className=" w-[15px] h-[16px]"
											src={require("../../Assets/HelevenVector.png")}
											alt=""
										/>
									</div>
									<div>
										<p className="">Emmanuel Olu</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* connector growth 2 */}
					<div className="grid justify-end items-center mr-[-195px] ">
						<div className=" w-[290px] h-auto flex justify-center items-center bg-white p-2 gap-2 text-black rounded-l-md  ">
							<div>
								<img
									className=" w-[161px] h-[112px]"
									src={require("../../Assets/Heleven10.png")}
									alt=""
								/>
							</div>
							<div className="grid gap-2 items-center">
								<div className=" flex items-center gap-2 ">
									<img
										className=" w-[70px] h-[70px]"
										src={require("../../Assets/Helevenpas2.png")}
										alt=""
									/>
									<p>10% Connection Growth</p>
								</div>
								<div className=" flex gap-2 justify-center">
									<div>
										<img
											className=" w-[15px] h-[16px]"
											src={require("../../Assets/HelevenVector.png")}
											alt=""
										/>
									</div>
									<div>
										<p className="">Samuel Eze</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* connector growth 3 */}
					<div className="grid justify-end items-center mr-[-130px] ">
						<div className=" w-[290px] h-auto flex justify-center items-center bg-white p-2 gap-2 text-black rounded-l-md  ">
							<div>
								<img
									className=" w-[161px] h-[112px]"
									src={require("../../Assets/Heleven11.png")}
									alt=""
								/>
							</div>
							<div className="grid gap-2 items-center">
								<div className=" flex items-center gap-2 ">
									<img
										className=" w-[70px] h-[70px]"
										src={require("../../Assets/Helevenpas4.png")}
										alt=""
									/>
									<p>10% Connection Growth</p>
								</div>
								<div className=" flex gap-2 justify-center">
									<div>
										<img
											className=" w-[15px] h-[16px]"
											src={require("../../Assets/HelevenVector.png")}
											alt=""
										/>
									</div>
									<div>
										<p className="">Jhon Mike</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div name="service" className=" bg-[#F3F7FA] mx-auto mt-10">
				<div className=" md:px-16 lg:px-28 px-8 py-10 font-Satoshi">
					<div>
						<p
							data-aos="fade-up"
							data-aos-duration="3000"
							className=" font-Satoshi lg:text-[42px] md:text-[32px] text-[32px]">
							OUR SERVICES
						</p>
					</div>
					<div className=" bg-white shadow-lg rounded-lg lg:space-y-10  px-10 py-5 ">
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className=" grid lg:grid-cols-3 gap-5">
							{cards.map((item, index) => (
								<div
									key={item.id}
									className={`grid justify-center px-5 py-3 rounded-md ${
										index === 0
											? "bg-gradient-to-tr from-[#D8F7EE] via-[#fff] to-[#EEEAFE] text-black col-span-1"
											: index === 1
											? "bg-gradient-to-tr from-[#E3FAE7] via-[#fff] to-[#EEEAFE] text-black col-span-1"
											: index === 2
											? "bg-gradient-to-tr from-[#FFE6E2] via-[#fff] to-[#EEEAFE] text-black col-span-1"
											: index === 3
											? "bg-gradient-to-tr from-[#EAE3FA] via-[#fff] to-[#EEEAFE] text-black "
											: "bg-gradient-to-tr from-[#FFE2EE] via-[#fff] to-[#EEEAFE] text-black "
									}`}>
									<div className=" grid justify-center items-center">
										<img
											className=" w-[64px] h-[64px]"
											src={item.image}
											alt=""
										/>
									</div>
									<p className=" font-bold text-[20px] text-center">
										{item.heading}
									</p>
									<p className=" text-center">{item.text}</p>
								</div>
							))}
						</div>

						<div
							data-aos="fade-down"
							data-aos-duration="3000"
							className=" grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 lg:mt-0 md:mt-10 mt-10 gap-10">
							{cardtwo.map((items, indextwo) => (
								<div
									key={items.id}
									className={`grid justify-center px-5 py-3 rounded-md ${
										indextwo === 0
											? "bg-gradient-to-tr from-[#EAE3FA] via-[#fff] to-[#EEEAFE] text-black "
											: indextwo === 1
											? "bg-gradient-to-tr from-[#FFE2EE] via-[#fff] to-[#EEEAFE] text-black "
											: indextwo === 2
									}`}>
									<div className=" grid justify-center items-center">
										<img
											className=" w-[64px] h-[64px]"
											src={items.images}
											alt=""
										/>
									</div>
									<p className=" font-bold text-[20px] text-center">
										{items.headings}
									</p>
									<p className=" text-center">{items.texts}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className=" md:px-16 lg:px-28 px-8">
				<div className=" lg:flex md:grid grid justify-center gap-5 items-center">
					<div
						data-aos="fade-right"
						data-aos-duration="3000"
						className=" lg:w-1/2 md:w-full w-full">
						<div className=" grid justify-center items-center">
							<img
								className=" h-[475px]"
								src={require("../../Assets/Heleven17.png")}
								alt=""
							/>
						</div>
					</div>
					<div
						data-aos="fade-left"
						data-aos-duration="3000"
						className=" lg:w-1/2 md:w-full w-full">
						<div className="grid justify-center items-center">
							<div>
								<p className=" font-Satoshi lg:text-[42px] md:text-[32px] text-[28px] font-bold">
									What People Are Saying About Us
								</p>
								<p className=" font-Alata">
									I can't imagine managing my business without this website.
									It's made everything so much easier!{" "}
								</p>
							</div>
							<div>
								<div>
									<img
										className=" w-[70px] h-[70px]"
										src={require("../../Assets/Heleven66.png")}
										alt=""
									/>
								</div>
								<p className=" font-Alata">
									I love the communication aspect of this website
								</p>

								<div className=" mt-5">
									<p className=" font-bold font-Satoshi">Ada Eze</p>
									<p className="font-Satoshi">FinTech, CEO</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className=" bg-[#F3F7FA] mx-auto">
				<div className="md:px-16 lg:px-28 px-8 py-10">
					<div className=" grid justify-center gap-5 items-center">
						<div>
							<p className=" text-[40px] font-sansation font-bold text-center">
								Subscribe to Our Newsletter
							</p>
							<p className=" font-Nunito text-center">
								To join the worldwide community
							</p>
						</div>

						<div className=" flex items-center justify-center">
							<input
								placeholder="Enter Your Email"
								className=" lg:w-[613px] md:w-[400px] w-[250px] h-[47px] md:h-[57px] pl-4 lg:pr-44 md:pr-32 pr-[100px] lg:h-[67px] border-none bg-white text-black relative lg:left-[77px] md:left-[50px] left-[35px] rounded-md"
								type="text"
							/>
							<button className=" font-Nunito bg-[#AD1DF1] text-white lg:w-[150px] md:w-[100px] w-[80px] h-[37px] md:h-[47px] lg:h-[57px] relative lg:right-[80px] md:right-[55px] right-[50px] rounded-md">
								Join Now
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* footer */}
			<div className=" bg-[#05021A] text-white font-Satoshi">
				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className="md:px-16 lg:px-28 px-8 py-10">
					<div className=" lg:flex md:grid grid justify-between">
						<div className=" grid gap-3 ">
							<div className=" text-lg font-semibold flex items-center">
								<AppLogo height={"50px"} />
								<p className=" font-bold text-[24px] font-sansation pt-2">
									{process.env.REACT_APP_AGENT_NAME}
								</p>
							</div>
							<div className=" w-[250px]">
								<p>
									{/* Lorem ipsum dolor sit amet consectetur. Ligula id facilisi sed
									aliquam dolor.{" "} */}
								</p>
							</div>
							{/* <div className=" flex gap-3 items-center">
								<img
									className=" w-[32px] h-[32px]"
									src={require("../../Assets/HelevenFacebook.png")}
									alt=""
								/>
								<img
									className=" w-[32px] h-[32px]"
									src={require("../../Assets/Heleventwitter.png")}
									alt=""
								/>
								<img
									className=" w-[32px] h-[32px]"
									src={require("../../Assets/HelevenInstagram.png")}
									alt=""
								/>
							</div> */}
							<ul className="list-group border-0 list-group-horizontal">
								{socials.map((item, index) => (
									<li
										key={index}
										className="list-group-item border-0 bg-transparent text-btncol d-flex align-items-center">
										<a
											className="text-btncol text-decoration-none fontReduce"
											target={"_blank"}
											rel="noreferrer"
											href={
												item?.type === "mail" && item?.url
													? `mailto:${item?.url}`
													: item?.url || "#"
											}>
											{item?.icon}
										</a>
									</li>
								))}
							</ul>
							<p className="lg:text-[22px] md:text-[20px] text-[20px] font-bold">
								Contact Us
							</p>
							<div>
								{/* <p>+234 800 000 0000</p> */}
								<p>{process.env.REACT_APP_AGENT_TELEPHONE}</p>
								<p>{process.env.REACT_APP_AGENT_EMAIL}</p>
							</div>
						</div>

						<ul className="grid lg:mt-0 md:mt-10 mt-10">
							<p className=" text-[16px]">Support</p>
							<li className=" text[16px]">Help centre</li>
							<li className=" text[16px]">Account information</li>
							<li className=" text[16px]">About</li>
							<li className=" text[16px]">Contact us</li>
						</ul>
						<ul className="grid">
							<p className=" text-[16px]">Help and Solution</p>
							<li className=" text[16px]">Talk to support</li>
							<li className=" text[16px]">Support docs</li>
							<li className=" text[16px]">System status</li>
							<li className=" text[16px]">Covid responde</li>
						</ul>
						<ul className="grid">
							<p className=" text-[16px]">Product</p>
							<li className=" text[16px]">Update</li>
							<li className=" text[16px]">Security</li>
							<li className=" text[16px]">Beta test</li>
							<li className=" text[16px]">Pricing product</li>
						</ul>
					</div>
					<div className=" lg:flex md:grid grid justify-between items-center mt-5">
						<div>
							<p className=" text-[i6px]">
								© 2023 {process.env.REACT_APP_AGENT_NAME}. Copyright and rights
								reserved
							</p>
						</div>
						<div className=" flex items-center gap-2">
							<p className=" text-[16px]">Terms and Condtions .</p>
							<p className=" text-[18px] ">Privacy Policy</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeEleven;
